// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {BGGZgC7fC: {hover: true}};

const cycleOrder = ["BGGZgC7fC", "kTB8fnOdV"];

const variantClassNames = {BGGZgC7fC: "framer-v-1q08hui", kTB8fnOdV: "framer-v-1crsb2i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "BGGZgC7fC", "Variant 2": "kTB8fnOdV"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "BGGZgC7fC", image: dn4r41MCl = {src: new URL("assets/2048/ipv3UqMvZ7tEJb95BuqEkt98ywM.png", import.meta.url).href, srcSet: `${new URL("assets/512/ipv3UqMvZ7tEJb95BuqEkt98ywM.png", import.meta.url).href} 512w, ${new URL("assets/1024/ipv3UqMvZ7tEJb95BuqEkt98ywM.png", import.meta.url).href} 1024w, ${new URL("assets/2048/ipv3UqMvZ7tEJb95BuqEkt98ywM.png", import.meta.url).href} 2048w, ${new URL("assets/ipv3UqMvZ7tEJb95BuqEkt98ywM.png", import.meta.url).href} 2240w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BGGZgC7fC", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6M1Lx", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-1q08hui", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BGGZgC7fC"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"BGGZgC7fC-hover": {"data-framer-name": undefined}, kTB8fnOdV: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Link href={"data:framer/page-link,lxOlIwydB"}><Image as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 297, intrinsicWidth: 366, pixelHeight: 297, pixelWidth: 366, ...toResponsiveImage_194x2gw(dn4r41MCl)}} className={"framer-1rxsdbm framer-s4mt0x"} data-framer-name={"image 21"} layoutDependency={layoutDependency} layoutId={"HcAerR91c"} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)"}} transition={transition} variants={{"BGGZgC7fC-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}, kTB8fnOdV: {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}}/></Link></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6M1Lx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6M1Lx * { box-sizing: border-box; }", ".framer-6M1Lx .framer-s4mt0x { display: block; }", ".framer-6M1Lx .framer-1q08hui { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 60px; height: min-content; justify-content: center; overflow: hidden; padding: 60px 30px 0px 30px; position: relative; width: 1140px; }", ".framer-6M1Lx .framer-1rxsdbm { aspect-ratio: 2.7 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 400px); overflow: hidden; position: relative; text-decoration: none; width: 1px; }", ".framer-6M1Lx .framer-v-1q08hui .framer-1q08hui { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6M1Lx .framer-1q08hui { gap: 0px; } .framer-6M1Lx .framer-1q08hui > * { margin: 0px; margin-left: calc(60px / 2); margin-right: calc(60px / 2); } .framer-6M1Lx .framer-1q08hui > :first-child { margin-left: 0px; } .framer-6M1Lx .framer-1q08hui > :last-child { margin-right: 0px; } }", ".framer-6M1Lx.framer-v-1crsb2i .framer-1q08hui { padding: 30px 0px 30px 0px; }", ".framer-6M1Lx.framer-v-1crsb2i .framer-1rxsdbm { height: var(--framer-aspect-ratio-supported, 422px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 460
 * @framerIntrinsicWidth 1140
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"kTB8fnOdV":{"layout":["fixed","auto"]},"OJ6HMJ5li":{"layout":["fixed","auto"]}}}
 * @framerVariables {"dn4r41MCl":"image"}
 */
const FramerOCiaZwGRN: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerOCiaZwGRN;

FramerOCiaZwGRN.displayName = "sona-visual-multiplication-cards";

FramerOCiaZwGRN.defaultProps = {height: 460, width: 1140};

addPropertyControls(FramerOCiaZwGRN, {variant: {options: ["BGGZgC7fC", "kTB8fnOdV"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, dn4r41MCl: {__defaultAssetReference: "data:framer/asset-reference,ipv3UqMvZ7tEJb95BuqEkt98ywM.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerOCiaZwGRN, [])